import { ItemCategoryCodeTypes, QuotingMethodTypes } from '@/enums'
import QuoteTotalsMethodMixin from './QuoteTotalsMethodMixin'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { ItemCategoryTypes } from '../../../enums'
import { QuoteItemModel } from '@/classes/viewmodels'
import _cloneDeep from 'lodash.clonedeep'

// import cloneDeep from 'lodash.clonedeep'

export default {
  mixins: [QuoteTotalsMethodMixin],
  computed: {},
  methods: {
    deleteItemAuthorise(item, index) {
      item.isQuoteItemDeleted = true
      if (item.isAdded) {
        item.isDeleted = true
      }
      this.updateAssessmentItem(index, item)
    },
    undoDeleteItem(item, index) {
      item.isQuoteItemDeleted = false
      this.updateAssessmentItem(index, item)
    },
    selectAllItems(value) {
      if (value !== null) {
        this.activeItems.forEach((item) => {
          item.isSelected = value
          this.updateAssessmentItem(this.activeItems.indexOf(item), item)
        })
      }
    },
    defaultSelectItems() {
      this.activeItems.forEach((item) => {
        item.isSelected = false
      })
    },
    addBlankItemAuthorise() {
      let rate = 0
      // const type = ItemCategoryTypes.RR
      switch (this.itemType) {
        case ItemCategoryTypes.RR:
          rate = this.isNtar ? this.value.shopRate : this.getLabourRate(ItemCategoryTypes.RR, this.value.rates, this.value.shopRate, this.value.quoteId).rate
          break
        case ItemCategoryTypes.RWA:
          rate = this.isNtar ? this.value.shopRate : this.getLabourRate(ItemCategoryTypes.RWA, this.value.rates, this.value.shopRate, this.value.quoteId).rate
          break
        case ItemCategoryTypes.REP:
          rate = this.isNtar ? this.value.shopRate : this.getLabourRate(ItemCategoryTypes.REP, this.value.rates, this.value.shopRate, this.value.quoteId).rate
          break
        case ItemCategoryTypes.PAINT:
          rate = this.isNtar ? this.value.shopRate : this.getLabourRate(this.vehicle.paintGroup, this.value.rates, this.value.shopRate, this.value.quoteId).rate
          break
      }
      let sortNo = 0
      if (ItemCategoryCodeTypes.itemTypes.filter((c) => c.category === 'Labour').some((c) => c.code === this.itemType)) {
        sortNo = this.value.labours.length ? Math.max(...this.value.labours.map((i) => i.sortNo)) + 1 : 1
      } else if (ItemCategoryCodeTypes.itemTypes.filter((c) => c.category === 'Part').some((c) => c.code === this.itemType)) {
        sortNo = this.value.parts.length ? Math.max(...this.value.parts.map((i) => i.sortNo)) + 1 : 1
      } else if (ItemCategoryCodeTypes.itemTypes.filter((c) => c.category === 'OPG').some((c) => c.code === this.itemType)) {
        sortNo = this.value.opgs.length ? Math.max(...this.value.opgs.map((i) => i.sortNo)) + 1 : 1
      } else if (ItemCategoryCodeTypes.itemTypes.filter((c) => c.category === 'Misc').some((c) => c.code === this.itemType)) {
        sortNo = this.value.miscs.length ? Math.max(...this.value.miscs.map((i) => i.sortNo)) + 1 : 1
      } else if (ItemCategoryCodeTypes.itemTypes.filter((c) => c.category === 'Sublet').some((c) => c.code === this.itemType)) {
        sortNo = this.value.sublets.length ? Math.max(...this.value.sublets.map((i) => i.sortNo)) + 1 : 1
      } else if (ItemCategoryCodeTypes.itemTypes.filter((c) => c.category === 'OtherLabour').some((c) => c.code === this.itemType)) {
        sortNo = this.value.others.length ? Math.max(...this.value.others.map((i) => i.sortNo)) + 1 : 1
      }
      let newItem = {
        submittedItem: null,
        authorisedItem: null
      }
      let emptyItem = new QuoteItemModel(this.innerValue.quoteId, '', '', this.itemType)
      emptyItem.isSelected = true
      emptyItem.itemDesc = ''
      emptyItem.isAdded = true
      emptyItem.itemType = this.itemType
      emptyItem.reportOnly = false
      emptyItem.lineNumber = this.nextLineNumber
      emptyItem.itemNo = this.$filters.pad(newItem.lineNumber, 4)
      emptyItem.hourValue = 0
      emptyItem.rate = rate
      emptyItem.dollarValue = 0
      emptyItem.opgCode = ItemCategoryCodeTypes.itemTypes.filter((c) => c.category === 'OPG').some((c) => c.itemType === this.itemType)
        ? this.vehicle.paintGroup
        : null
      emptyItem.sortNo = sortNo
      emptyItem.isNew = true
      emptyItem.itemType = this.itemType

      if (this.editQuotedMode) {
        newItem.submittedItem = _cloneDeep(emptyItem)
      }
      if (this.editAuthorisedMode) {
        newItem.authorisedItem = _cloneDeep(emptyItem)
      }

      return newItem
    },
    getQuoteItemAssessments(assessments, item) {
      let sortedAssessments = assessments.sort((a1, a2) => a1.subQuoteNo - a2.subQuoteNo)
      let quoteItemAssessments = []
      sortedAssessments.forEach((assessment) => {
        const quoteItemSubmitted = assessment?.submittedAssessment?.assessmentQuoteItems?.find((aqi) => aqi.quoteItemId === item.quoteItemId)
        const quoteItemAuthorised = assessment?.authorisedAssessment?.assessmentQuoteItems?.find((aqi) => aqi.quoteItemId === item.quoteItemId)
        if (quoteItemAuthorised) {
          quoteItemAssessments.push([quoteItemSubmitted, quoteItemAuthorised])
        } else if (quoteItemSubmitted) {
          quoteItemAssessments.push([quoteItemSubmitted])
        }
      })
      return quoteItemAssessments
    },
    getItemsAssessments(items, quoteAssessments) {
      let itemsAssessments = []
      items.forEach((item) => {
        let itemAssessments = {}
        itemAssessments.quoteItemId = item.quoteItemId
        itemAssessments.assessments = this.getQuoteItemAssessments(quoteAssessments, item)
        if (itemAssessments.assessments.length > 0) {
          itemsAssessments.push(itemAssessments)
        }
      })
      return itemsAssessments
    },
    getItemValue(item, quotingMethod) {
      if (item.itemType === ItemCategoryTypes.PART) {
        return item.itemQuantity * item.markupValue
      } else if (item.itemType === ItemCategoryTypes.MISC || item.itemType === ItemCategoryTypes.SUBL) {
        return item.value
      } else {
        return quotingMethod === QuotingMethodTypes.Dollar ? item.dollarValue : item.hourValue * item.rate
      }
    },
    getItemDeltaValue(item, quotingMethod) {
      if (item.itemType === ItemCategoryTypes.PART) {
        return item.itemQuantity * item.markupValueDelta + item.itemQuantityDelta * item.markupValue - item.itemQuantityDelta * item.markupValueDelta
      } else if (item.itemType === ItemCategoryTypes.MISC || item.itemType === ItemCategoryTypes.SUBL) {
        return item.valueDelta
      } else {
        return quotingMethod === QuotingMethodTypes.Dollar
          ? item.dollarValueDelta
          : item.hourValue * item.rateDelta + item.hourValueDelta * item.rate - item.hourValueDelta * item.rateDelta
      }
    },
    itemsChanged(items, quotingMethod) {
      return items.filter((i) => this.itemChanged(i, i.lastQuotedAssessment, i.lastAuthorisedAssessment, quotingMethod))
    },
    itemChanged(item, lastQuoteAssessment, lastAuthorisedAssessment, quotingMethod) {
      //if no assessment it is new
      if (!lastQuoteAssessment && !lastAuthorisedAssessment) {
        if (item.deleted) {
          return false
        }
        return true
      }
      let itemAssessment = lastAuthorisedAssessment ? lastAuthorisedAssessment : lastQuoteAssessment
      return this.itemValueChanged(item, itemAssessment, quotingMethod)
    },
    itemValueChanged(item, itemAssessment, quotingMethod) {
      if (!itemAssessment) {
        return item.deleted !== true //item is new (assessment is not available)
      }
      if (item.deleted) {
        return itemAssessment.isQuoteItemDeleted !== true //item is deleted and already assessed(no changes)
      }
      if (itemAssessment.reportOnly !== item.reportOnly) return true
      if (itemAssessment.value !== item.value) return true
      if (itemAssessment.isQuoteItemDeleted !== item.deleted) return true
      if (ItemCategoryCodeTypes.itemTypes.filter((c) => c.valueCalculationType === 'Labour').some((c) => c.code === item.itemType)) {
        if (itemAssessment.dollarValue !== item.dollarValue) return true
        if (itemAssessment.hourValue !== item.hourValue && quotingMethod === QuotingMethodTypes.Hour) return true
      }
      if (ItemCategoryCodeTypes.itemTypes.filter((c) => c.category === 'Part').some((c) => c.code === item.itemType)) {
        if (itemAssessment.itemQuantity * itemAssessment.markupValue !== item.itemQuantity * item.markupValue) return true
      }
      if (ItemCategoryCodeTypes.itemTypes.filter((c) => c.category === 'Misc' || c.category === 'Sublet').some((c) => c.code === item.itemType)) {
        if (itemAssessment.value !== item.value) return true
      }
      return false
    },
    //true if item deleted before any assessment
    isItemDeleted(item, assessmentQuoteItems = null) {
      if (assessmentQuoteItems === null) {
        return item.deleted || item.isDeleted
      } else if (item.deleted || item.isDeleted) {
        if (assessmentQuoteItems.length > 0) return false
        else return true
      }
      return false
    },
    //true if Item is deleted but has an assessment
    isItemRemoved(item, assessmentQuoteItems) {
      //if retrieved item is already deleted:
      //  if there is an assessment for the item, return true
      if (item.deleted || item.isDeleted) {
        if (assessmentQuoteItems.length > 0) return true
        else return false
      }
      return false
    },
    rowDisplayStatus(item, assessmentQuoteItems) {
      let classText = []
      if (this.isItemRemoved(item, assessmentQuoteItems)) {
        classText.push('strike-through-line')
      }
      return classText
    },
    revalue(newValue, oldValue, items) {
      let total = 0
      items
        .filter((i) => !i.reportOnly && !(i.deleted || i.isDeleted))
        .forEach((item, index) => {
          const percentage = item.dollarValue / oldValue
          item.dollarValue = roundAwayFromZero(percentage * newValue)
          if (index === items.length - 1) {
            item.dollarValue = roundAwayFromZero(newValue - total)
          }
          item.hourValue = roundAwayFromZero(item.dollarValue / item.rate)
          total = roundAwayFromZero(total + item.dollarValue)
        })
    },
    getAssessmentItemsDeltaTotal(items, quotingMethod) {
      let total = 0
      items
        .filter((i) => i.deleted !== true && i.reportOnly !== true)
        .forEach((item) => {
          if (item.isQuoteItemDeleted) {
            total += this.getItemDeltaValue(item, quotingMethod) - this.getItemValue(item, quotingMethod)
          } else {
            total += this.getItemDeltaValue(item, quotingMethod)
          }
        })
      return total
    },
    reportOnlyColorClass(item) {
      let classText = []
      if (item.reportOnly) {
        classText.push('report-only-value')
      }
      return classText
    },
    tableCellColorClass(item, targetItem) {
      let classText = []
      if (item) {
        const itemValue = this.getItemValue(item, this.value.quotingMethod)
        const targetItemValue = targetItem ? this.getItemValue(targetItem, this.value.quotingMethod) : 0
        if (itemValue > targetItemValue) {
          classText.push('success-bg')
        }
        if (itemValue < targetItemValue) {
          classText.push('danger-bg')
        }
      }
      return classText
    },
    tableCellTextColorClass(item, targetItem) {
      let classText = []
      if (item && targetItem) {
        const itemValue = this.getItemValue(item, this.value.quotingMethod)
        const targetItemValue = targetItem ? this.getItemValue(targetItem, this.value.quotingMethod) : 0
        if (itemValue > targetItemValue) {
          classText.push('is-success has-text-success')
        }
        if (itemValue < targetItemValue) {
          classText.push('is-danger has-text-danger')
        }
      }
      return classText
    },
    createAssessment(items, subQuoteNo, assessorName = null, isDraft = true) {
      if (items.length === 0) return null
      const assessment = {
        assessorName: assessorName,
        subQuoteNo: subQuoteNo,
        isDraft: isDraft,
        isAuthorise: false,
        isInvoiced: false,
        authorisedAssessment: null,
        submittedAssessment: this.createAssessmentDetails(items, isDraft, false)
      }
      return assessment
    },
    createAssessmentDetails(items, isDraft = true, isAuthorise = false) {
      let assessmentDetail = {
        assessmentQuoteItems: this.createAssessmentQuoteItems(items, isAuthorise),
        isDraft: isDraft
      }
      return assessmentDetail
    },
    createAssessmentQuoteItems(items, isAuthorise = false) {
      if (!items || items.length === 0) return []
      return items.map((i) => {
        return {
          quoteItemId: i.quoteItemId,
          itemType: i.itemType,
          itemNo: i.itemNo,
          itemDesc: i.itemDesc,
          hourValue: i.hourValue,
          rate: i.rate,
          dollarValue: i.dollarValue,
          partNo: i.partNo,
          partSource: i.partSource,
          itemComment: i.itemComment,
          itemQuantity: i.itemQuantity,
          value: i.value,
          reportOnly: i.reportOnly,
          isQuoteItemDeleted: isAuthorise ? i.isQuoteItemDeleted : i.deleted || i.isDeleted,
          markupValue: i.markupValue,
          mark: i.mark,
          buyPrice: i.buyPrice,
          buyPriceDelta: this.calculateDeltaValue(i.buyPrice, i.lastAuthorisedAssessment?.buyPrice, i.lastQuotedAssessment?.buyPrice),
          dollarValueDelta: this.calculateDeltaValue(i.dollarValue, i.lastAuthorisedAssessment?.dollarValue, i.lastQuotedAssessment?.dollarValue),
          hourValueDelta: this.calculateDeltaValue(i.hourValue, i.lastAuthorisedAssessment?.hourValue, i.lastQuotedAssessment?.hourValue),
          itemQuantityDelta: this.calculateDeltaValue(i.itemQuantity, i.lastAuthorisedAssessment?.itemQuantity, i.lastQuotedAssessment?.itemQuantity),
          markupValueDelta: this.calculateDeltaValue(i.markupValue, i.lastAuthorisedAssessment?.markupValue, i.lastQuotedAssessment?.markupValue),
          rateDelta: this.calculateDeltaValue(i.rate, i.lastAuthorisedAssessment?.rate, i.lastQuotedAssessment?.rate),
          valueDelta: this.calculateDeltaValue(i.value, i.lastAuthorisedAssessment?.value, i.lastQuotedAssessment?.value),
          lineNumber: i.lineNumber,
          opgCode: i.opgCode,
          vendorName: i.vendorName,
          deleted: false,
          isSelected: false,
          hasAuthoriseHistory: isAuthorise ? i.hasAuthoriseHistory : i.lastAuthorisedAssessment !== null
        }
      })
    },
    calculateDeltaValue(value, authorisedValue, quotedValue) {
      if (authorisedValue) {
        return value - authorisedValue
      } else if (quotedValue) {
        return value - quotedValue
      } else {
        return value
      }
    },
    calculateAssessmentItemCategoryTotal(quotingMethod, items, itemType) {
      let total = 0
      if (itemType === ItemCategoryTypes.PART) {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly && !i.isQuoteItemDeleted)
          .reduce(function (total, item) {
            return roundAwayFromZero(total + item.itemQuantity * item.markupValue)
          }, 0)
      } else if (itemType === ItemCategoryTypes.MISC) {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly && !i.isQuoteItemDeleted)
          .reduce(function (total, item) {
            return total + item.value
          }, 0)
      } else if (itemType === ItemCategoryTypes.OPG) {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly && !i.isQuoteItemDeleted)
          .reduce(function (total, item) {
            return total + (quotingMethod === QuotingMethodTypes.Dollar ? item.dollarValue : item.hourValue * item.rate)
          }, 0)
      } else if (itemType === ItemCategoryTypes.SUBL) {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly && !i.isQuoteItemDeleted)
          .reduce(function (total, item) {
            return total + item.value
          }, 0)
      } else {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly && !i.isQuoteItemDeleted && i.itemType === itemType)
          .reduce(function (total, item) {
            return roundAwayFromZero(total + item.dollarValue)
          }, 0)
      }
      return total
    },
    isItemInvoiced(item) {
      var invoiced = false
      this.innerValue.quoteAssessments
        .filter((a) => a.isInvoiced === true)
        .forEach((assessment) => {
          if (assessment.authorisedAssessment) {
            var itemAssessments = assessment.authorisedAssessment.assessmentQuoteItems.find((i) => i.quoteItemId === item.quoteItemId)
            if (itemAssessments) {
              invoiced = true
            }
          }
        })
      return invoiced
    }
  }
}
